import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import config from './config/config';
import Home from './generalPages/home';
import Connectwallet from './generalPages/connectwallet';
import Signup from './generalPages/userlogin';
import Selectwallet from './generalPages/selectwallet';
import Signupsuccess from './generalPages/signupsuccess';
import Scanwallet from './generalPages/scanwallet';
import Vesting from './generalPages/vesting';
import About from './generalPages/about';
import Ourcoin from './generalPages/ourcoin';
import Tokenomics from './generalPages/tokenomics';
import Pagenotfound from './generalPages/pagenotfound';
import Userregister from './generalPages/userregister';
import Userlogin from './generalPages/userlogin';
import Presale from './generalPages/preSale';
import ForgetPassword from './generalPages/forgetPassword';
import ResetPassword from './generalPages/resetPassword';
import PrivacyPolice from './generalPages/privacyPolicy';
import Privatesale from './generalPages/privatesale';
function App() {
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Home />} />
          <Route path={`${config.baseUrl}userregister`} element={<Userregister />} />
          <Route path={`${config.baseUrl}connectwallet`} element={<Connectwallet />} />
          <Route path={`${config.baseUrl}userlogin`} element={<Userlogin />} />
          <Route path={`${config.baseUrl}userlogin/:encryptemail`} element={<Userlogin />} />
          <Route path={`${config.baseUrl}selectwallet`} element={<Selectwallet />} />
          <Route path={`${config.baseUrl}signupsuccess`} element={<Signupsuccess />} />
          <Route path={`${config.baseUrl}scanwallet`} element={<Scanwallet />} />
          <Route path={`${config.baseUrl}vesting`} element={<Vesting />} />
          <Route path={`${config.baseUrl}about`} element={<About />} />
          <Route path={`${config.baseUrl}ourcoin`} element={<Ourcoin />} />
          <Route path={`${config.baseUrl}tokenomics`} element={<Tokenomics />} />
          <Route path={`${config.baseUrl}Presale`} element={<Presale />} />
          <Route path={`${config.baseUrl}ForgetPassword`} element={<ForgetPassword />} />
          <Route path={`${config.baseUrl}ResetPassword/:encryptemail`} element={<ResetPassword />} />
          <Route path={`${config.baseUrl}privacyPolice`} element={<PrivacyPolice />} />
          <Route path={`${config.baseUrl}privatesale`} element={<Privatesale />} />




          {/* <Route path={`/*`} element={<Pagenotfound />} /> */}
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;

