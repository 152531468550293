import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../generalCSS/home.css";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Accordion,
} from "react-bootstrap";
import Header from "./header";
import Footer from "./footer";
import { Zoom, Fade, Slide, Roll, LightSpeed, Bounce } from "react-reveal";
import config from "../config/config";
const Home = () => {
  const navigate = useNavigate();
  const [reloadData, setreloadData] = useState(
    !localStorage.getItem('reloadData')
      ? ''
      : localStorage.getItem('reloadData')
  );

  useEffect(() => {
    // alert(reloadData)
    if (reloadData == '') {
      // alert('32424332')
      setTimeout(() => {
        localStorage.setItem('reloadData', true);
        window.location.reload()
      },);
    }
    //     else{
    // alert('11111')
    //       setTimeout(() => {
    //         localStorage.setItem('reloadData', true);
    //         window.location.reload()
    //       }, 500);
    //     }

  }, [])
  const preSalePage = () => {
    // navigate(`${config.baseUrl}Presale`);
    localStorage.setItem('reloadData', '');
  };
  return (
    <>
      <Header />

      {/*------------- Banner Section Start -------------- */}

      {/* <div className='main_banner'> */}
      <section className="banner py-5 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={7} md={12} xs={12} className="d-flex align-items-center">
              <Fade left>
                <div className="brn_text">
                  <h1 className="mb-4">
                    Make your life <br /> easier with LikeBit
                  </h1>
                  <p>
                    LikeBits (LBT and LBTS) are two new cryptocurrencies associated with the LikeBit app, an innovative app that connects, for the first time, the digital market with physical stores.
                 
                    Users can mine LBTS by spending time on social media platforms, watching ads, and redeeming its value for purchases. Alternatively, they can invest directly in LBT, gaining great benefits by participating in the LikeBit project, and helping to fund a revolution.
                  </p>

                  <div className="">
                    <Row>
                      <Col lg={5} md={12} xs={12} className="pt-2">
                        <a target="blank" href={`${config.baseUrl}Presale`}>
                          <div onClick={preSalePage}>
                            <Button
                              variant="custombtn3"
                              type="button"
                              className="px-5"
                            >
                              <span className="py-1 px-3">Buy Now</span>
                            </Button>
                          </div>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Fade>
            </Col>

            <Col lg={5} md={12} xs={12} className="">
              <Zoom>
                <div className="">
                  <img
                    src="assets/images/bnrImage.png"
                    alt="bnrImage"
                    className="bnrImage"
                  />
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>
      {/* </div> */}
      <Fade bottom>
        <div className="traditional_box pt-5">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <div className="headingbox2">
                  <center> <h2> JOIN THE REVOLUTION</h2></center>
                </div>
                <div>
                </div>
                <br />
                <center>
                  <Button
                    variant="custombtn3"
                    type="button"
                    className="px-5"
                  >
                    <span className="py-3 px-3"> <a href="http://likebit.io/" target="blank"> READ MORE</a> </span>
                  </Button>
                </center>
                {/* <a href="http://likebit.io/" target="blank"> <p style={{ textDecoration: 'underline' }}>READ MORE</p></a> */}
              </Col>
            </Row>
          </Container>
        </div>
      </Fade>

      {/*------------- Banner Section Exit -------------- */}

      {/*------------- Whychoose Section Start -------------- */}

      <section className="whychhose_sec pt-5">
        <Container>
          {/* <Row>
                        <Col lg={12} md={12}>
                            <div className='headingbox'>
                                <h6>WHY CHOOSE US ?</h6>
                                <p>LikeBitcoin is a reliable ally in the rapidly changing world of digital banking, providing a safe platform for those looking for a life-changing bitcoin experience.</p>
                            </div>
                        </Col>
                    </Row> */}

          <Row>
            <Col lg={6} md={12} xs={12}>
              <Slide left>
                <div className="headingbox">
                  <h1>WHY
                    CHOOSE LIKEBIT?</h1>
                  <p>
                    LikeBit is a steadfast partner in the dynamic realm of digital finance, offering a secure ecosystem for individuals seeking a transformative cryptocurrency experience.
                  </p>
                  <p>PROFIT NOW! INVEST IN LBT AND STEP INTO THE LIKEBIT FUTURE
                  </p>
                </div>
              </Slide>
              <div className="financial_box pt-5">
                <ul>
                  <LightSpeed left>
                    <li>
                      <div className="">
                        <img
                          src="assets/icons/financial_icon.svg"
                          alt="financial_icon"
                          className="financial_icons"
                        />
                        <span>Financial Security at the Core</span>
                      </div>
                    </li>
                  </LightSpeed>
                  <LightSpeed left>
                    <li>
                      <div className="">
                        <img
                          src="assets/icons/intuitive_icon.svg"
                          alt="intuitive_icon"
                          className="financial_icons"
                        />
                        <span>Intuitive User Experience</span>
                      </div>
                    </li>
                  </LightSpeed>
                  <LightSpeed left>
                    <li>
                      <div className="">
                        <img
                          src="assets/icons/tech_icon.svg"
                          alt="tech_icon"
                          className="financial_icons"
                        />
                        <span>Technology at the Cutting Edge</span>
                      </div>
                    </li>
                  </LightSpeed>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- Whychoose Section Exit -------------- */}

      {/*------------- Feature Section Start -------------- */}

      <section className="feature_sec py-5">
        <Container>
          <Row>
            <Col lg={7} md={7} xs={12} className="mb-5">
              <Slide left>
                <div className="headingbox">
                  <h1>Our Features </h1>
                  <p>
                    Features Explore a realm of possibilities with our innovative features crafted to revolutionize your digital financial journey.
                  </p>
                </div>
              </Slide>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} xs={12} className="mb-5">
              <Zoom>
                <div className="feature_box">
                  <div className="">
                    <img
                      src="assets/images/featureimg1.png"
                      alt="featureimg1"
                      className="featureimg"
                    />
                  </div>
                  <div className="featureText ms-lg-4 ms-md-4 ms-0">
                    <p>
                      Experience swift and secure transactions, ensuring the efficiency and peace of mind of your financial interactions as you engage with LikeBit's network.
                    </p>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col lg={6} md={6} xs={12} className="mb-5">
              <Zoom>
                <div className="feature_box pe-lg-5 pe-md-0">
                  <div className="">
                    <img
                      src="assets/images/featureimg2.png"
                      alt="featureimg2"
                      className="featureimg"
                    />
                  </div>
                  <div className="featureText ms-lg-4 ms-md-4 ms-0">
                    <p>
                      Take the reins of your experience with our intuitive design, empowering you to seamlessly navigate the platform, fostering a deeper connection with every interaction.
                    </p>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col lg={6} md={6} xs={12} className="mb-5">
              <Zoom>
                <div className="feature_box">
                  <div className="">
                    <img
                      src="assets/images/featureimg3.png"
                      alt="featureimg3"
                      className="featureimg"
                    />
                  </div>
                  <div className="featureText ms-lg-4 ms-md-4 ms-0">
                    <p>
                      Rest assured knowing your funds and personal data are safeguarded with advanced privacy measures, prioritizing your confidentiality as you participate in LikeBit's ecosystem.
                    </p>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col lg={6} md={6} xs={12} className="mb-5">
              <Zoom>
                <div className="feature_box pe-lg-5 pe-md-0">
                  <div className="">
                    <img
                      src="assets/images/featureimg4.png"
                      alt="featureimg4"
                      className="featureimg"
                    />
                  </div>
                  <div className="featureText ms-lg-4 ms-md-4 ms-0">
                    <p>
                      {" "}
                      Embrace cutting-edge technology that keeps you ahead in the ever-evolving landscape of digital finance, granting you the ability to shape the unfolding of your financial journey.
                    </p>
                  </div>
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- Feature Section Exit -------------- */}

      {/*------------- Roadmap Section Start -------------- */}

      <section className="roadmap_sec py-5 d-flex justify-content-center align-items-center">
        <Container>
          <Row>
            <Col lg={7} md={7} xs={12} className="mb-5">
              <Slide left>
                <div className="headingbox">
                  <h1>ROADMAP</h1>
                  <p>
                    "LikeBit:  One Application, Countless Opportunities!"
                  </p>
                </div>
              </Slide>
            </Col>
          </Row>
<br />
          <Row>
            <Col lg={12} md={12} xs={12} className="roadmap_content">
              <div className="road_box1">
                <div className="">
                  <img
                    src="assets/images/roadimg1.png"
                    alt="roadimg1"
                    className="roadimg"
                  />
                </div>
                <div className="">
                  <h6>Websites <br />
                    Development
                  </h6>
                  {/* <p>
                    Download likeBit app from your respective app stores.
                  </p> */}
                </div>
              </div>

              <div className="road_box2">
                <div className="">
                  <h6>Cryptocurrency <br />
                    Integration</h6>
                  {/* <p>
                    You can easily connect all your social media accounts.
                  </p> */}
                </div>
                <div className="">
                  <img
                    src="assets/images/roadimg2.png"
                    alt="roadimg2"
                    className="roadimg"
                  />
                </div>
              </div>

              <div className="road_box3">
                <div className="">
                  <img
                    src="assets/images/roadimg3.png"
                    alt="roadimg3"
                    className="roadimg"
                  />
                </div>
                <div className="">
                  <h6>  Testing and
                    Community <br /> Development
                  </h6>
                  {/* <p>
                    You can earn likeBit token by interacting on social media.
                  </p> */}
                </div>
              </div>

              <div className="road_box4">
                <div className="">
                  <h6>Sale Phases <br />Initiation
                  </h6>
                  {/* <p>
                    You can redeem tokens for discounts at participating businesses.
                  </p> */}
                </div>
                <div className="">
                  <img
                    src="assets/images/roadimg4.png"
                    alt="roadimg4"
                    className="roadimg"
                  />
                </div>
              </div>

              <div className="road_box5">
                <div className="">
                  <img
                    src="assets/images/roadimg5.png"
                    alt="roadimg5"
                    className="roadimg"
                  />
                </div>
                <div className="">
                  <h6>Application <br /> Development</h6>
                  {/* <p>
                    You can enjoy savings and support local businesses effortlessly!
                  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- Roadmap Section Exit -------------- */}

      {/*------------- FAQ Section Start -------------- */}

      <section className="faq_sec py-5">
        <Container>
          <Row>
            <Col lg={7} md={7} xs={12} className="mb-3">
              <Slide left>
                <div className="headingbox">
                  <h1>FAQ</h1>
                  <p>Your Questions Answered</p>
                </div>
              </Slide>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={4} md={12} xs={12} className="">
              <Zoom>
                <div className="gen_question">
                  <ul>
                    <li className="active">
                      <a href="#">
                        <img
                          src="assets/icons/Polygon 1.svg"
                          alt="Polygon 1"
                          className="polygon_icon"
                        />
                        <span>General Question</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="assets/icons/Polygon 2.svg"
                          alt="Polygon 2"
                          className="polygon_icon"
                        />
                        <span>ICO Question</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="assets/icons/Polygon 2.svg"
                          alt="Polygon 2"
                          className="polygon_icon"
                        />
                        <span>Tokens Sales</span>
                      </a>
                    </li>
                    <li className="mb-2">
                      <a href="#">
                        <img
                          src="assets/icons/Polygon 2.svg"
                          alt="Polygon 2"
                          className="polygon_icon"
                        />
                        <span>Clients Related</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Zoom>
            </Col> */}
            <Col lg="12" md="12" xs="12" className="">
              {/* <Slide right> */}
              <div className="solu_right">
                <Accordion defaultActiveKey="0" className="">
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header className="py-3">
                      How do I get started with LikeBit?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      You just simply register in our platfform and while you will connect your wallet, you are good to go!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="">
                    <Accordion.Header className="py-3">
                      Is LikeBit secure?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      LikeBit is based on Etherium VM and netwrok and following all the standars for safe decantrillized transactions.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="">
                    <Accordion.Header className="py-3">
                      What wallets are compatible with LikeBit?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      Metamask in first phase and we are aiming to add more in the future.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="">
                    <Accordion.Header className="py-3">
                      What makes LikeBit unique?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                    LikeBit rewards the user and the businesses in an equavalient way, so it's a win win situation, What also makes it unique
                      is the way the coin gets produced.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4" className="">
                    <Accordion.Header className="py-3">
                      Can I download the LikeBit whitepaper?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      Yes you can download it or see it online!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {/* </Slide> */}
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- FAQ Section Exit -------------- */}

      {/* <Fade bottom> */}
      <Footer />
      {/* </Fade> */}
    </>
  );
};

export default Home;
