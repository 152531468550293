import React from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../generalCSS/tokenomics.css";
import { Container, Row, Col, Button, Card, Form, Accordion } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';
import CountUp from 'react-countup';
import { FaEuroSign } from "react-icons/fa6";
import config from '../config/config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import TokonomicChart from './tokenomicChart';
const Tokenomics = () => {


    const copieBtn = async () => {
        toast.success(`Copied!!`);
    }
    return (
        <>
            <Header />
            <Toaster />



            {/*------------- Tokenomics Section Start -------------- */}

            <section className='tokenomics_sec pt-5'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} xs={12} className=' mx-auto'>
                            <div className='customhead text-center'>
                                <h2 className='mb-3'>Token Sale</h2>
                                <h3 className='mb-3'>Tokenomics</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm lg={11} md={12} xs={12} className='mx-auto d-flex justify-content-center align-items-center'>
                            
                                {/* <TokonomicChart/> */}
                                {/* <img src="assets/images/tokenomicImg.png" alt="tokenomicImg" className='tokenomicImg' /> */}
                                 <img src="assets/images/meta-chart (1).png" alt="chartTokenomix" className='tokenomicImg' />
                        </Col>
                    </Row>
                </Container>
            </section>


            {/*------------- Tokenomics Section Exit -------------- */}





            {/*------------- Tokenomics Section Start -------------- */}

            <section className='tokendetails_sec py-5'>
                <Container>
                    <Row>
                        <Col lg={7} md={7} xs={12} className='mb-5 mx-auto'>
                            <div className='customhead text-center'>
                                <h2 className='mb-3'>Token Details</h2>
                                <p>Use the contract information below to add the LikeBit to your wallet.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12} xs={12} className='mb-lg-0 mb-2 '>
                            <div className='tokenLeftbox py-4 px-lg-2'>
                                <div className='tokenInner mb-4 px-3 d-flex flex-wrap justify-content-start align-itens-start gap-4'>
                                    <div className=''>
                                        <h4>Token Name</h4>
                                        <p>LikeBit </p>
                                    </div>
                                    <div className=''>
                                        <h4> Phase </h4>
                                        <p>1</p>
                                    </div>
                                    <div className=''>
                                        <h4>Network</h4>
                                        <p>Ethereum</p>
                                    </div>
                                    <div className=''>
                                        <h4>Token Type</h4>
                                        <p>ERC-20</p>
                                    </div>
                                </div>
                                <div className='tokenInner mb-lg-5 mb-md-5 mb-3 px-3 d-flex flex-wrap justify-content-start align-itens-start gap-4'>
                                    <div className=''>
                                        <h4>Token Symbol </h4>
                                        <p>LBT</p>
                                    </div>
                                    <div className=''>
                                        <h4>Decimal</h4>
                                        <p>18</p>
                                    </div>

                                </div>
                                <div className='tokenInner px-3 mt-1 pt-3 '>
                                    <div className='tokenaddress pt-1 mt-1'>
                                        <h4>Token Contract Address</h4>

                                        <div className=''>
                                            {/* <Form autoComplete="off">

                                                <Row>
                                                    <Col xs={5}>
                                                        <Form.Group className=" tokenInput">
                                                            <Form.Control type="text" placeholder="0x012345esdrfs...............014hdgdhfh" autoComplete="off" />
                                                            <span>
                                                                <img src="assets/icons/tokencopy.svg" alt="tokencopy" className='tokencopy' />
                                                            </span>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form> */}

                                            <div className='contactadd'>
                                                <span> {config.cntAddress.substring(0, 6) + '....' + config.cntAddress.substring(config.cntAddress.length - 6)}
                                                    &nbsp; <CopyToClipboard text={config.cntAddress}>
                                                        <span title="Click to Copy" onClick={copieBtn} style={{ cursor: "pointer", color: '#f45246' }}>
                                                            <img src="assets/icons/tokencopy.svg" alt="tokencopy" className='tokencopy' />
                                                        </span>
                                                    </CopyToClipboard></span>
                                                {/* <span className='ms-3'>
                                                    <img src="assets/icons/tokencopy.svg" alt="tokencopy" className='tokencopy' />
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} xs={12} className=''>
                            <div className='tokenRightbox p-4 pb-1'>

                                <div className=' mb-4 '>
                                    <div className=''>
                                        <h4 className=''> Private Sale:</h4>
                                        <p className='mb-0'>Total number of coins: 1,000,000 LBT</p>
                                        <p className='mb-0'>Sale price: $1.25 for the first 500,000 coins and $1.50  for the remaining.</p>
                                    </div>
                                </div>
                                <div className=' mb-4 '>
                                    <div className=''>
                                        <h4 className=''> Pre Sale:</h4>
                                        <p className='mb-0'>Total number of coins: 1,000,000 LBT</p>
                                        <p className='mb-0'>Sale price:  $1.75 for the first 500,000 coins and $2 for the remaining.</p>
                                    </div>
                                </div>
                                <div className=' mb-4 '>
                                    <div className=''>
                                        <h4 className=''> Exchange:</h4>
                                        <p className='mb-0'>Total number of coins: 5,500,000 LBT</p>
                                        <p className='mb-0'>Sale price: $2.25 </p>
                                    </div>
                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            {/*------------- Tokenomics Section Exit -------------- */}




            <Footer />



        </>
    )
}

export default Tokenomics;
