import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../generalCSS/privatesale.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Accordion,
} from "react-bootstrap";
import Header from "./header";

import config from "../config/config";
import { Zoom, Fade, Slide, Roll, LightSpeed, Bounce } from "react-reveal";
import { PiEqualsBold } from "react-icons/pi";


const Privatesale = () => {
  return (
    <>

      <Header />




      <section className="privatesale pt-5 pb-5 mb-5">
        <Container>

          <Row className="">
            <Col lg={12} md={12} xs={12} className="mx-auto mb-5">
              <div className="privateTimes">
                <div className="">
                  <h1 className="mb-4">Private Sale Phase Ends In</h1>
                  <Row className="">
                    <Col lg={6} md={12} xs={12} className="mx-auto mb-5">
                      <div className="timephase mb-3">
                        <ul>
                          <li>
                            <span>01</span>
                          </li>
                          <li>
                            <span>21</span>
                          </li>
                          <li>
                            <span>56</span>
                          </li>
                          <li>
                            <span>18</span>
                          </li>
                        </ul>
                      </div>

                      <div className="dayhours">
                        <ul>
                          <li>
                            Days
                          </li>
                          <li>
                            Hours
                          </li>
                          <li>
                            Minutes
                          </li>
                          <li>
                            Seconds
                          </li>

                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6} md={12} xs={12} className="d-flex align-items-center">
              <div className="getstart_block p-xl-5 p-lg-5 p-md-5 p-3" style={{ minHeight: "500px" }}>
                <div className="formbox px-lg-4 ">
                  <Form autoComplete="off">
                    <div className="brn_text">
                      <div
                        class="col-lg-12"
                        style={{
                          padding: "12px",
                          background: "transparent",
                        }}
                      >
                        <div class="">
                          <div class="text-center cardBox mt-5">
                            <h6 className="mb-3 fs-2">1 LBT = 1.25 USDT</h6>
                            <Button
                              variant=""
                              style={{ border: "1px solid" }}
                              type="button" className="px-4 mb-5 fs-6 btn btn-primary-mainbtn1"
                            >
                              CONNECT WALLET
                            </Button>

                            <div class="">
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="type" placeholder="Enter USDT Amount" />
                              </Form.Group>
                              <div>
                                <Button
                                  variant="refreshbtn"
                                  type="button" className="px-4"
                                >
                                  <span><PiEqualsBold className="equalIcon" /></span>
                                </Button>
                              </div>

                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="type" placeholder="LBT Amount" />
                              </Form.Group>
                            </div>

                            <Button
                              variant=""
                              style={{ border: "1px solid" }}
                              type="button" className="px-4 w-100 mb-3 fs-6 btn btn-primary-mainbtn1 mt-4"
                            >
                              Buy LBT
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} xs={12} className="d-flex align-items-center">
              <div className="handcoin_box py-lg-5 py-md-5 py-3">
                <img
                  src="assets/images/handcoin.png"
                  alt="handcoin"
                  className="handcoin"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>


    </>
  )
}

export default Privatesale;
