import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../config/config";
import "../generalCSS/signup.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { IoEyeOutline, IoEyeOffSharp } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { resetAction } from "../Action/user.action";
import Header from "./header";

const ResetPassword = () => {
  let { encryptemail } = useParams();
  const [data, setdata] = useState({
    confirmPassword: "",
    password: "",
    token: encryptemail,
  });
  const [validationerror, setvalidationerror] = useState({
    confirmPasswordError: "",
    passworderror: "",
  });
  const [eyeDisplay, setEyedisplay] = useState(true);
  const [eyeDisplay1, setEyedisplay1] = useState(true);

  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);

  const [resendMailShow, setResendMailShow] = useState(false);
  const navigate = useNavigate();

  const inputhandler = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
    setvalidationerror({ ...validationerror, [name]: "" });
  };

  const validations = () => {
    let isValid = true;
    const errors = {};
    const passwordFormat =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[^\s]).{8,}$/;

    if (!data.password) {
      errors.passworderror = "Create you password";
      isValid = false;
    } else if (data.password.length < 8) {
      errors.passworderror = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!passwordFormat.test(data.password)) {
      errors.passworderror =
        "Password must contain at least one capital letter, one small letter, one special character and one numeric";
      isValid = false;
    }

    if (!data.confirmPassword) {
      errors.confirmPasswordError = "Enter your confirm  password";
      isValid = false;
    } else if (data.confirmPassword.length < 8) {
      errors.confirmPasswordError =
        "Confirm Password must be at least 8 characters long";
      isValid = false;
    } else if (!passwordFormat.test(data.confirmPassword)) {
      errors.confirmPasswordError =
        "Confirm Password must contain at least one capital letter, one small letter, one special character and one numeric";
      isValid = false;
    }

    if (data.password != data.confirmPassword) {
      errors.confirmPasswordError = "Password Not Matched";
      isValid = false;
    }

    setvalidationerror(errors);
    return isValid;
  };

  const showpasswordfunc1 = () => {
    setEyedisplay(false);
    setshowpassword(!showpassword);
  };

  const showpasswordfunc = () => {
    setEyedisplay(true);

    setshowpassword(!showpassword);
  };

  const showpasswordfunc2 = () => {
    setEyedisplay1(true);
    setshowpassword1(!showpassword1);
  };

  const showpasswordfunc3 = () => {
    setEyedisplay1(false);

    setshowpassword1(!showpassword1);
  };

  const resetPasswordAction = async (e) => {
    e.preventDefault();

    const isValid = validations();

    if (!isValid) {
    } else {
      try {
        let res = await resetAction(data);

        if (res.success) {
          toast.success(res.msg);

          setTimeout(() => {
            navigate(`${config.baseUrl}userlogin`);
          }, 1000);
        } else {
          setResendMailShow(true);
          toast.error(res.msg);
        }
      } catch (error) {
        toast.error("Something Went Wrong! Please Try Again");
      }
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="signup_sec pt-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} xs={12}>
              <Row>
                <Col lg={6} md={12} xs={12} className="">
                  <div className="getstart_block p-xl-5 p-lg-5 p-md-5 p-3">
                    <div className="headbox">
                      <p className="">
                        Don’t have any account?{" "}
                        <span>
                          <Link
                            style={{ textDecoration: "underline" }}
                            to={`${config.baseUrl}userregister`}
                          >
                            Sign up
                          </Link>
                        </span>
                      </p>
                    </div>

                    <div className="google_box">
                      <Row>
                        <Col
                          lg={10}
                          md={12}
                          className="px-xl-5 px-lg-0 px-md-2 my-4 mx-auto"
                        ></Col>
                      </Row>
                    </div>

                    <div className="formbox px-lg-4 ">
                      <Form autoComplete="off">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type={`${showpassword ? "name" : "password"}`}
                            name="password"
                            onChange={inputhandler}
                            placeholder="Enter Password"
                            autoComplete="off"
                          />
                          {eyeDisplay == false ? (
                            <IoEyeOutline
                              className="eyeicon"
                              style={{
                                cursor: "pointer",
                                float: "right",
                                marginTop: "-36px",
                                marginRight: "11px",
                              }}
                              onClick={showpasswordfunc}
                            />
                          ) : (
                            <IoEyeOffSharp
                              className="eyeicon"
                              style={{
                                cursor: "pointer",
                                float: "right",
                                marginTop: "-36px",
                                marginRight: "11px",
                              }}
                              onClick={showpasswordfunc1}
                            />
                          )}
                        </Form.Group>
                        <div
                          style={{ marginTop: "-15px" }}
                          className="validationerror"
                        >
                          {validationerror.passworderror}
                        </div>

                        <br />
                        <Form.Group
                          className="mb-4 fieldbox"
                          controlId="formBasicPassword"
                        >
                          <Form.Control
                            type={`${showpassword1 ? "name" : "password"}`}
                            name="confirmPassword"
                            onChange={inputhandler}
                            placeholder="Enter Confirm Password"
                          />
                          {console.log('eyeDisplay1',eyeDisplay1)}
                          {eyeDisplay1 == false ? (
                            <IoEyeOutline
                              className="eyeicon"
                              style={{ cursor: "pointer" }}
                              onClick={showpasswordfunc2}
                            />
                          ) : (
                            <IoEyeOffSharp
                              className="eyeicon"
                              style={{ cursor: "pointer" }}
                              onClick={showpasswordfunc3}
                            />
                          )}
                        </Form.Group>
                        <div
                          style={{ marginTop: "-15px" }}
                          className="validationerror"
                        >
                          {validationerror.confirmPasswordError}
                        </div>

                        <br />
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto">
                            <Button
                              variant="custombtn2"
                              type="button"
                              className="px-4 py-lg-3 py-md-3 py-2 w-100"
                              onClick={resetPasswordAction}
                            >
                              Reset Password{" "}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <div className="handcoin_box py-lg-5 py-md-5 py-3">
                    <img
                      src="assets/images/handcoin.png"
                      alt="handcoin"
                      className="handcoin"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
