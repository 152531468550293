import React from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../generalCSS/tokenomics.css";
import { Container, Row, Col, Button, Card, Form, Accordion } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';
import CountUp from 'react-countup';
import { FaEuroSign } from "react-icons/fa6";
import config from '../config/config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';

const PrivacyPolice = () => {


    const copieBtn = async () => {
        toast.success(`Copied!!`);
    }
    return (
        <>
            <Header />
            <Toaster />



            {/*------------- Tokenomics Section Start -------------- */}

            <section className='tokenomics_sec pt-5'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mb-5 mx-auto'>
                            <div className='customhead '>
                                <h2 className='mb-3'>Privacy Policy</h2>

                                <p style={{ fontSize: '20px' }}>
                                    LBT token, including but not limited to the overall project, website smart contracts, and software app (“LikeBit”) as presented in this conceptual paper is not currently an active licensed or exempted financial or payment service of any kind and in any jurisdiction. However, it might be in the future, subject to appropriate requirements and approval. Any financial or payment services terminology used in this Whitepaper, on the LikeBit website, or within the demo app is intended only as a basic reference, without any effective or legal meaning of the same terms in a regulated or traditional financial environment.
                                    The LBT token is a governance token in any jurisdiction and is not and cannot be considered as a security or otherwise a regulated token of any kind, is not in any way e-money or a fiat or asset-backed stablecoin, whether global or limited in scope. The token does not fall into the category of securities, stocks, or any other type found in regulated markets. It's simply a digital asset used within a specific platform or application to provide various services, privileges, or functions. Its main feature is that it is not an investment in the traditional sense, such as owning shares in a company. Instead, the token is used to fund and build the app and facilitate activities within it, without providing ownership rights or a stake in the business.
                                    This White Paper does not constitute a contract or contractual agreement of any kind and does not constitute a solicitation or offer to invest in LikeBit or acquire or use its tokens in any way with any expectation of profit. Each user of LikeBit represents that they have taken appropriate technical, financial, administrative, and legal advice before and after reading this White Paper, the LikeBit website, and using any part of the LikeBit application (including its token) and accepts that there is an inherent high risk the acquisition or use of any kind of blockchain and/or crypto token, platform, software, interface and acknowledges with a full disclaimer for any of the persons or entities mentioned in this White Paper, that there may be any kind of damage, including of the total loss.
                                    This Whitepaper is not a contract or a contractual agreement of any kind and is not an invitation or offer to invest in LikeBit or acquire or use its tokens in any way with any expectation of profit. Any user of LikeBit declares to have received appropriate, financial, technical, administrative, and legal advice before and after reading this Whitepaper, the LikeBit Website, and using any part of the LikeBit App (including its token) and accepts that there is an inherent high risk in acquiring or using any kind of blockchain and/or crypto token, platform, software, interface and acknowledges with full disclaimer for any of the persons or entities mentioned within this Whitepaper, that there can be any kind of damage suffered, including total loss. Accepting the above, investors acknowledge the significant risk of partial or total loss of their capital and explicitly declare that under any circumstances, they will not pursue legal action against LIKEBIT SMPC.</p>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>


            {/*------------- Tokenomics Section Exit -------------- */}





            {/*------------- Tokenomics Section Start -------------- */}



            {/*------------- Tokenomics Section Exit -------------- */}




            <Footer />



        </>
    )
}

export default PrivacyPolice;
