import React, { useState } from 'react';
import Web3 from 'web3';
import web3config from './web3config';
import presaleAbi from '../abi/presaleABI.json';
import usdtAbi from '../abi/usdtABI.json';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const useBuyToken = () => {
  const [usdtAmount, setUsdtAmount] = useState('');
  const [usdAmountData, setUsdAmoutData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [redirect, setredirect] = useState(false);
  const [realAmountData, setrealAmountData] = useState(
    !localStorage.getItem('realAmountData')
      ? ''
      : localStorage.getItem('realAmountData')
  );
  const buyTokens = async () => {
    const web3 = new Web3(window.ethereum);
    if (window.ethereum) {
      try {
        setIsDialogOpen(true);
        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        });
        if (chainId !== web3config.CHAIN) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3config.CHAIN }],
            });
          } catch (switchError) {
            setIsDialogOpen(false);
          }
        }
        setIsDialogOpen(true);

        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];

        const preSaleContractAddress = web3config.preSaleContract;
        const usdtContractAddress = web3config.usdtContract;
        const contractPreSale = new web3.eth.Contract(
          presaleAbi,
          preSaleContractAddress
        );
        const contractUsdt = new web3.eth.Contract(
          usdtAbi,
          usdtContractAddress
        );
        //console.log('amtusdt', usdAmountData); //2
        const usdtAmountInWei = usdAmountData * 10 ** 6;
        //console.log('amt', usdtAmountInWei); //2000000
        let checkAllowance = await contractUsdt.methods
          .allowance(userAddress, preSaleContractAddress)
          .call();
        //console.log('amt2', checkAllowance); //1000000

        let allowanceAmount = parseFloat(parseInt(checkAllowance) / 10 ** 6); //2

        if (allowanceAmount < usdAmountData) {
          //console.log('amt3', contractUsdt.methods);
          const approval = await contractUsdt.methods.approve(
            preSaleContractAddress,
            usdtAmountInWei.toString()
          );
          let trx = await approval.encodeABI();
          let gasPrice = await web3.eth.getGasPrice();
          let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: usdtContractAddress,
            from: userAddress,
            data: trx,
            //nonce: await web3.eth.getTransactionCount(userAddress),
          });
          const approvalTrx = {
            from: userAddress,
            to: usdtContractAddress,
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            data: trx,
            //  nonce: await web3.eth.getTransactionCount(userAddress),
          };

          const approvalReceipt = await web3.eth.sendTransaction(approvalTrx);
        }

        const data = await contractPreSale.methods.buy(usdtAmountInWei);

        let gasPrice = await web3.eth.getGasPrice();
        let gasLimit = await web3.eth.estimateGas({
          to: preSaleContractAddress,
          from: userAddress,
          data: data.encodeABI(),
          gasPrice: web3.utils.toHex(gasPrice),
          //nonce: await web3.eth.getTransactionCount(userAddress),
        });
        const trx = {
          from: userAddress,
          to: preSaleContractAddress,
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          data: data.encodeABI(),
          //nonce: await web3.eth.getTransactionCount(userAddress),
        };
        const receipt = await web3.eth.sendTransaction(trx);
        const newBlock = await receipt.blockNumber;
        const checkBlock = await axios.get(
          'https://likebittoken.com/admin/blockNumber'
        );
        if (checkBlock.data.data === 0) {
          await axios.post('https://likebittoken.com/admin/updateBlock', {
            blockNumber: newBlock,
          });
        }
        let finalAmount =
          parseFloat(realAmountData) - parseFloat(usdAmountData);
        localStorage.setItem('realAmountData', finalAmount);
        setTimeout(() => {
          toast.success('Tokens bought successfully!');
        }, 500);
        setIsDialogOpen(false);
        setredirect(true);
      } catch (err) {
        console.log(err, 'ygfv');
        setIsDialogOpen(false);
        if (err.message.toString().split('Insufficient fund')[1]) {
          toast.error('Transaction reverted : ' + err.message);
        } else {
          if (err.toString().split('execution reverted:')[1]) {
            toast.error(
              'Transaction reverted : ' +
                err
                  .toString()
                  .split('execution reverted:')[1]
                  .toString()
                  .split('{')[0]
            );
          } else {
            toast.error(
              err.message ==
                'Already processing eth_requestAccounts. Please wait.'
                ? 'Please open your MetaMask as the transaction is already in progress.'
                : err.message
            );
          }
        }
        // toast.error("Error buying tokens. Please try again.");
      }
    } else {
      setIsDialogOpen(false);

      toast.error('Please install MetaMask to proceed.');
    }
  };
  return {
    usdtAmount,
    setUsdAmoutData,
    setUsdtAmount,
    buyTokens,
    isDialogOpen,
    redirect,
  };
};
export { useBuyToken };
