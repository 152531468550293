import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../config/config";
import "../generalCSS/signup.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { IoEyeOutline, IoEyeOffSharp } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import {
  LoginAction,
  ResendEmailAction,
  verifyAccountAction,
  googleLoginAction,
} from "../Action/user.action";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Header from "./header";

const Userlogin = () => {
  const [data, setdata] = useState({
    email: "",
    password: "",
  });
  const [validationerror, setvalidationerror] = useState({
    emailerror: "",
    passworderror: "",
  });
  const [eyeDisplay, setEyedisplay] = useState(true);

  const [showpassword, setshowpassword] = useState(false);
  const [resendMailShow, setResendMailShow] = useState(false);
  const navigate = useNavigate();
  let { encryptemail } = useParams();

  useEffect(() => {
    console.log(encryptemail);
    if (encryptemail) {
      verifyAccount(encryptemail);
    }
  }, [encryptemail]);

  //===========================  verify acccount=================

  const verifyAccount = async (encryptemail) => {
    try {
      let res = await verifyAccountAction(encryptemail);
      if (res.success) {
        toast.success(res.msg);
        setResendMailShow(false);
      } else {
        toast.error(res.msg);
        setResendMailShow(true);
      }
    } catch (error) {
      // console.log(error.response.data.msg,'ddddddddddddddd');
      // toast.error(error.response.data.msg);
    }
  };

  const inputhandler = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
    setvalidationerror({ ...validationerror, [name]: "" });
  };

  const validations = () => {
    let isValid = true;
    const errors = {};
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data.email) {
      errors.emailerror = "Enter your email id";
      isValid = false;
    } else if (!emailFormat.test(data.email)) {
      errors.emailerror = "Invalid email format";
      isValid = false;
    }
    if (!data.password) {
      errors.passworderror = "Enter your password";
      isValid = false;
    }

    setvalidationerror(errors);
    return isValid;
  };

  const showpasswordfunc = () => {
    setEyedisplay(true);
    setshowpassword(!showpassword);
  };

  const showpasswordfunc1 = () => {
    setEyedisplay(false);
    setshowpassword(!showpassword);
  };


  const LoginUser = async (e) => {
    e.preventDefault();
    console.log(validationerror);
    const isValid = validations();
    console.log(isValid);
    if (!isValid) {
      console.log("invalid form");
    } else {
      try {
        let res = await LoginAction(data);
        console.log(res, "res");
        if (res.success) {
          toast.success(res.msg);
          Cookies.set("likeBitUser", JSON.stringify(res.data));

          setTimeout(() => {
            navigate(`${config.baseUrl}Connectwallet`);
          }, 1000);
        } else {
          setResendMailShow(true);
          toast.error(res.msg);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong! Please Try Again");
      }
    }
  };

  const responseGoogle = async (response) => {
    try {
      const res = await googleLoginAction({
        firstName: response.given_name,
        lastName: response.family_name,
        email: response.email,
        registrationType: 2,
        isEmailVerify: 1,
        userType: 1,
      });

      if (res.success === true) {
        console.log(res);
        Cookies.set("likeBitUser", res.data);

        setTimeout(() => {
          navigate(`${config.baseUrl}Connectwallet`);
        }, 1000);
        toast.success(res.msg);
      }
    } catch (err) {
      toast.error(err.response);
    }
  };

  const VerifyEmail = async (e) => {
    e.preventDefault();
    console.log(validationerror);

    if (data.email == "") {
      setvalidationerror((prevState) => ({
        ...prevState,
        emailerror: "Email is required",
      }));
    } else {
      try {
        let res = await ResendEmailAction(data);
        console.log(res, "res");
        if (res.success) {
          toast.success(res.msg);
          //   setTimeout(() => {
          //     navigate(`${config.baseUrl}`);
          //   }, 1000);
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong! Please Try Again");
      }
    }
  };

  const forgetPasswordPage = () => {
    window.location.href = `${config.baseUrl}ForgetPassword`
  };
  return (
    <>
      {/* <section className="pt-4">
        <Toaster />
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Link to={`${config.baseUrl}`}>
                <img
                  src="assets/images/Likebit_Logo_Purple_.png"
                  alt="Logo"
                  style={{ width: "145px" }}
                  className="main_logo"
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}
      <Header />
      <Toaster />
      <section className="signup_sec pt-5  ">
        <Container>
          {/* <Row>
                        <Col lg={6} md={6} xs={12}>
                            <img
                                src="assets/images/Likebit_Logo_Purple_.png"
                                alt="Logo"
                                className="main_logo"
                            />
                        </Col>
                    </Row> */}

          <Row className="align-items-center">
            <Col lg={12} md={12} xs={12}>
              <Row className="">
                <Col lg={6} md={12} xs={12} className=" ">
                  <div className="getstart_block  d-flex justify-content-center align-items-center flex-column">
                    <div className="headbox">
                      <p className="">
                        Don’t have any account?{" "}
                        <span>
                          <Link
                            style={{ textDecoration: "underline" }}
                            to={`${config.baseUrl}userregister`}
                          >
                            Sign up
                          </Link>
                        </span>
                      </p>
                    </div>

                    <div className="google_box">
                      <Row>
                        <Col
                          lg={10}
                          md={12}
                          className="px-xl-5 px-lg-0 px-md-2 my-4 mx-auto"
                        >
                          {/* <Button
                            variant=""
                            type="button"
                            className="googlebtn w-100"
                          > */}
                          {/* <span>
                              <img
                                src="assets/icons/Google.svg"
                                alt=" Google"
                                className=" Googleicon me-3"
                                style={{ width: "35px", height: "35px" }}
                              />
                            </span>
                            Sign in with Google */}
                          {/* <GoogleOAuthProvider
                              clientId="1018403538313-1u3n5j591qoqcq9pvt0fuvopd9irj0p0.apps.googleusercontent.com"
                              className="gmail"
                            >
                              <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                  const credentialResponseDecode = jwtDecode(
                                    credentialResponse.credential
                                  );
                                  console.log(credentialResponseDecode);
                                  responseGoogle(credentialResponseDecode);
                                }}
                                onError={() => {
                                  console.log("Login Failed");
                                }}
                              />
                            </GoogleOAuthProvider> */}
                          {/* </Button> */}
                        </Col>
                      </Row>
                    </div>

                    {/* <div className="ortext my-3">
                      <span></span>
                      <p>Or</p>
                      <span></span>
                    </div> */}
                    {/* mt-5 */}
                    <div className="formbox w-100 px-5 ">
                      <Form autoComplete="off">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={inputhandler}
                            placeholder="Enter Email Id"
                            autoComplete="off"
                          />
                          <div
                            className="validationerror"
                          >
                            {validationerror.emailerror}
                          </div>
                        </Form.Group>


                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="formBasicPassword"
                        >
                          <Form.Control
                            type={`${showpassword ? "name" : "password"}`}
                            name="password"
                            onChange={inputhandler}
                            placeholder="Enter Password"
                          />
                          <div className="eyeIconBox">
                          {eyeDisplay == false ? (
                            <IoEyeOutline
                              className="eyeicon"
                              style={{ cursor: "pointer" }}
                              onClick={showpasswordfunc}
                            />
                          ) : (
                            <IoEyeOffSharp
                              className="eyeicon"
                              style={{ cursor: "pointer" }}
                              onClick={showpasswordfunc1}
                            />
                          )}
                          </div>
                          <div
                          className="validationerror"
                        >
                          {validationerror.passworderror}
                        </div>
                        </Form.Group>
                        

                        <div className="mb-4">
                          <Link to={`${config.baseUrl}forgetPassword`} className="text-black">
                            Forgot Password
                          </Link>
                        </div>
                        {/* <div style={{cursor:"pointer"}} onClick={forgetPasswordPage}>Forget Password</div> */}
                        {/* {resendMailShow == true ? (
                          <Row>
                            <Col lg={12} md={12} className=" mx-auto">
                              <Button
                                variant="custombtn2"
                                type="button"
                                onClick={VerifyEmail}
                              >
                                Resend Email
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )} */}
                      
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto">
                            <Button
                              variant="custombtn2"
                              type="button"
                              className="px-4 py-lg-3 py-md-3 py-2 w-100"
                              onClick={LoginUser}
                            >
                              Sign In
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <div className="handcoin_box py-lg-5 py-md-5 py-3">
                    <img
                      src="assets/images/Handpresale.png"
                      alt="handcoin"
                      className="handcoin"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Userlogin;
