import React from "react";
import { Chart } from "react-google-charts";

export const data = [
    ["Task", "Hours per Day"],
    ["Private Sale", 10],
    ["Advertisments", 5],
    ["Company", 20],
    ["Exchanges", 55],
    ["Pre Sale", 10],
];

export const options = {
    title: "Total - 10,000,000",
    is3D: true,
    pieSliceText: 'percentage',
    pieSliceText: 'percentage', // Display percentage values
    // pieSliceTextPosition: 'outside', // Show percentage outside the pie
    pieSliceText: 'none',
    titleTextStyle: {
        color: "#333",
        fontSize: 20,
        marginTop:80,
        fontName: "Arial",
        bold: true, 
        italic: false, 
        
    },
};

const TokonomicChart = () => (
    <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width={"1500px"}
        height={"500px"}
        style={{ marginLeft: "-40px", marginTop: '10px' }}
    />
);

export default TokonomicChart;

