import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import config from '../config/config';
import "../generalCSS/signupsuccess.css";
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { IoEyeOutline } from "react-icons/io5";
import Header from "./header";
import Footer from "./footer";
import Cookies from "js-cookie";

const Signupsuccess = () => {

    const [emailShowData, setemailShowData] = useState(
        !Cookies.get("emailShow") ? [] : JSON.parse(Cookies.get("emailShow"))
      );

    return (
        <>

            {/* <section className='pt-4'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <Link to={`${config.baseUrl}`}>
                                <img
                                    src="assets/images/Likebit_Logo_Purple_.png"
                                    alt="Logo" style={{width:"145px"}}
                                    className="main_logo"
                                />
                            </Link>
                        </Col>
                    </Row>

                </Container>
            </section> */}

      <Header />



            <section className='signup_success pt-5'>
                <Container>
                    {/* <Row>
            <Col lg={6} md={6} xs={12}>
              <img
                src="assets/images/Likebit_Logo_Purple_.png"
                alt="Logo"
                className="main_logo"
              />
            </Col>
          </Row> */}

                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <Row>
                                <Col lg={6} md={12} xs={12} className=''>
                                    <div className='getstart_block p-5 d-flex justify-content-center flex-column'>
                                        <div className='text-center'>
                                            <img
                                                src="assets/images/menimg.png"
                                                alt="menimg"
                                                className="menimg"
                                            />

                                        </div>
                                        <div className='headbox mt-4'>
                                            <h1>Thanks!</h1>
                                        </div>
                                        <div className='thankstext'>
                                            <p className='mb-2'>We've sent a confirmation email to</p>
                                            <span>{emailShowData}</span>
                                        </div>


                                    </div>
                                </Col>


                                <Col lg={6} md={12} xs={12} className='d-flex align-items-center'>
                                    <div className='handcoin_box py-lg-5 py-md-5 py-3'>
                                        <img
                                            src="assets/images/handcoin.png"
                                            alt="handcoin"
                                            className="handcoin"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </section>
            {/* <Footer/> */}
        </>
    )
}

export default Signupsuccess;
